<template>
  <div
    id="registration-form-container"
    class="csn-user-register-form-container"
  >
    <Message
      :type="messageTypeEnum.FAILURE"
      :message="message"
      :hasBottom="true"
    />
    <Form class="csn-user-register-form" :ref="formRef" :id="formId">
      <div class="col-md-12 mb-3">
        <TextField
          :type="inputType.EMAIL"
          :name="name.EMAIL"
          :label="t('email_label')"
          v-model="formData[name.EMAIL]"
          :validators="[validator.required, validator.email]"
          :serverError="serverError[name.EMAIL]"
          @blur="onEmailBlur"
          autocomplete="off"
        />
      </div>
      <div class="col-md-12 mb-3">
        <PasswordField
          :name="name.PASSWORD"
          :label="t('password_label')"
          v-model="formData[name.PASSWORD]"
          :validators="[validator.required, validator.password]"
          :serverError="serverError[name.PASSWORD]"
          @blur="onPasswordBlur"
        />
      </div>
      <div class="col-md-12 mb-3">
        <PasswordField
          :name="name.PASSWORD_CONFIRMATION"
          :label="t('password_confirmation_label')"
          v-model="formData[name.PASSWORD_CONFIRMATION]"
          :validators="[
            validator.required,
            validator.isSameAs(name.PASSWORD, formId),
          ]"
        />
      </div>
      <div class="col-md-12 mb-3">
        <div class="form-group">
          <CheckboxField
            :name="name.NEWSLETTER_SUBSCRIPTION"
            :label="t('newsletter_subscription_label')"
            v-model="formData[name.NEWSLETTER_SUBSCRIPTION]"
          />
        </div>
      </div>
      <div class="col-md-12 mb-3">
        <div class="form-group">
          <CheckboxField
            :name="name.TERMS"
            :label="t('terms_label')"
            v-model="formData[name.TERMS]"
            :validators="[validator.checked]"
            :serverError="serverError[name.TERMS]"
          />
        </div>
      </div>
      <button
        class="btn casino-btn casino-btn-main-menu pull-left btn-full-width csn-user-register-btn casino-btn-theme-based"
        @click="submitForm"
        :disabled="isSubmitting"
      >
        <ButtonLoader v-if="isSubmitting" />
        <span v-else>{{ t('register_btn_label') }}</span>
      </button>
    </Form>
    <div class="row">
      <div class="col-md-12">
        <div class="line"></div>
      </div>
    </div>
    <div class="row footer-links">
      <div class="col-md-12">
        <span class="re-send-confirmation-link">
          {{ t('resend_confirmation_mail') }}
          <a @click="handleResendConfirmClick">
            {{ t('click_here') }}
          </a>
        </span>
        <span class="login">
          <a @click="handleLoginClick">
            {{ t('login') }}
          </a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { formMixin } from '@/mixins'
import {
  REGISTRATION_FORM,
  RouteName,
  EMPTY_STRING,
  Digit,
  RoutePath,
  SLASH,
  Module,
  CURRENT_LANGUAGE,
} from '@/constants'
import { MainApi, UserApi } from '@/api'
import { navigateTo, remove, isNotNil } from '@/helpers'

const name = {
  EMAIL: 'user[email]',
  PASSWORD: 'user[password][first]',
  PASSWORD_CONFIRMATION: 'user[password][second]',
  NEWSLETTER_SUBSCRIPTION: 'user[is_subscribed_to_newsletter]',
  TERMS: 'user[accept_terms_and_conditions]',
}

const formData = {
  [name.EMAIL]: EMPTY_STRING,
  [name.PASSWORD]: EMPTY_STRING,
  [name.PASSWORD_CONFIRMATION]: EMPTY_STRING,
  [name.NEWSLETTER_SUBSCRIPTION]: false,
  [name.TERMS]: false,
}

const serverErrorFieldNameDictionary = {
  email: [name.EMAIL],
  password: [name.PASSWORD],
  accept_terms_and_conditions: [name.PASSWORD_CONFIRMATION],
}

export default {
  name: REGISTRATION_FORM,
  mixins: [formMixin],
  components: {
    Form: () => import('@/components/FormData'),
    TextField: () => import('@/components/TextField'),
    CheckboxField: () => import('@/components/CheckboxField'),
    ButtonLoader: () => import('@/components/ButtonLoader'),
    Message: () => import('@/components/atoms/Message'),
    PasswordField: () => import('@/components/PasswordField'),
  },
  data() {
    return {
      formData: { ...formData },
    }
  },
  props: {
    formId: String,
  },
  computed: {
    ...mapState(Module.LANGUAGE, [CURRENT_LANGUAGE]),
    t() {
      return this.$createComponentTranslator(REGISTRATION_FORM)
    },
    route: () => ({
      resendConfirmMail: RouteName.RESEND_CONFIRM_MAIL,
      login: RouteName.LOGIN,
    }),
    name() {
      return name
    },
  },
  watch: {
    formData: {
      deep: true,
      handler(formData) {
        const keys = Object.keys(this.serverError)
        const keysToRemove = []
        this.message = null

        if (!keys.length) {
          return
        }

        keys.forEach(
          (key) =>
            formData[key] !== this.formDataSubmitCopy[key] &&
            keysToRemove.push(key),
        )
        this.serverError = remove(keysToRemove)(this.serverError)
      },
    },
  },
  methods: {
    onEmailBlur(value) {
      if (!value) {
        return
      }

      const { isValid } = this.$refs[this.formRef].getValidation(name.EMAIL)

      if (!isValid) {
        return
      }

      UserApi.checkEmailStatus({
        email: this.formData[name.EMAIL],
        isPnP: false,
      }).then(({ state, msg }) => {
        if (state === this.responseState.ERROR) {
          this.serverError = {
            ...this.serverError,
            [name.EMAIL]: msg[Digit.NOUGHT],
          }
          this.formDataSubmitCopy = { ...this.formData }
        }
      })
    },
    onPasswordBlur() {
      if (this.serverError[name.PASSWORD]) {
        this.serverError = remove([name.PASSWORD])(this.serverError)
      }
    },
    async handleResendConfirmClick() {
      await this.$emit('closeModal')
      this.$router.push({ name: this.route.resendConfirmMail })
      this.$emit('resendConfirmMail')
    },
    async handleLoginClick() {
      await this.$emit('closeModal')
      this.$router.push({ name: this.route.login })
      this.$emit('login')
    },
    async submitForm() {
      const { isValid } = this.$refs[this.formRef].getValidation()

      if (!isValid) {
        return
      }

      this.isSubmitting = true
      const locale = this.CURRENT_LANGUAGE
      const origin = window.location.origin

      const request = {
        form: {
          email: this.formData[name.EMAIL],
          password: {
            first: this.formData[name.PASSWORD],
            second: this.formData[name.PASSWORD_CONFIRMATION],
          },
          is_subscribed_to_newsletter: this.formData[
            name.NEWSLETTER_SUBSCRIPTION
          ],
          accept_terms_and_conditions: this.formData[name.TERMS],
          registration_confirm_route_path: `${origin}${SLASH}${locale}${SLASH}${RoutePath.Registration.CONFIRM}`,
        },
      }

      const response = await MainApi.register(request)

      if (!this.validateResponse(response)) {
        return
      }

      const { data, state, msg } = response

      if (state === this.responseState.ERROR) {
        const serverError = {}

        Object.keys(data.errors || {}).forEach((key) => {
          serverError[serverErrorFieldNameDictionary[key]] =
            data.errors[key].msg
        })

        this.serverError = serverError
        this.formDataSubmitCopy = { ...this.formData }
        this.message = msg
      }

      if (state === this.responseState.OK) {
        this.serverError = {}
        this.formDataSubmitCopy = {}
        this.$refs[this.formRef].reset()
        isNotNil(this.message) && (this.message = null)
        await this.$emit('closeModal')
        navigateTo({ name: RouteName.REGISTRATION_SUCCESS })
      }

      this.isSubmitting = false
    },
  },
}
</script>

<style scoped></style>
